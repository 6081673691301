<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      steps: [],
    };
  },
  created() {},
  props: {
    active: {
      type: Number || String,
      default: 0,
    },
    activeColor: {
      type: String,
      default: '#00ca5a',
    },
  },
  watch: {
    steps: {
      handler(data) {
        data.forEach((item, index) => {
          item.index = index;
        });
      },
      immediate: true,
    },
  },
};
</script>
